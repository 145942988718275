import React from 'react';
import './gallery.css'; // Import the CSS file
import { useNavigate } from 'react-router-dom';
import Image1 from '../../Assets/gallery/1.jpg';
import Image2 from '../../Assets/gallery/2.jpg';
import Image3 from '../../Assets/gallery/3.jpg';
import Image4 from '../../Assets/gallery/4.jpg';
import Image5 from '../../Assets/gallery/5.jpg';
import Image6 from '../../Assets/gallery/6.jpg';
import Image7 from '../../Assets/gallery/7.jpg';
import Image8 from '../../Assets/gallery/8.jpg';
import Image9 from '../../Assets/gallery/9.jpg';
import Image10 from '../../Assets/gallery/10.jpg';
import Image11 from '../../Assets/gallery/11.jpg';
import Image12 from '../../Assets/gallery/12.jpg';
import Image13 from '../../Assets/gallery/13.jpg';
import Image14 from '../../Assets/gallery/14.jpg';
import Image15 from '../../Assets/gallery/15.jpg';
import Image16 from '../../Assets/gallery/16.jpg';
import Image17 from '../../Assets/gallery/17.jpg';
import Image18 from '../../Assets/gallery/18.jpg';
import Image19 from '../../Assets/gallery/19.jpg';
import Image20 from '../../Assets/gallery/20.jpg';
import Image21 from '../../Assets/gallery/21.jpg';
import Image22 from '../../Assets/gallery/22.jpg';
import Image23 from '../../Assets/gallery/23.jpg';
import Image24 from '../../Assets/gallery/24.jpg';
import Image25 from '../../Assets/gallery/25.jpg';
import Image26 from '../../Assets/gallery/26.jpg';
import Image27 from '../../Assets/gallery/27.jpg';
import Image28 from '../../Assets/gallery/28.jpg';
import Image29 from '../../Assets/gallery/29.jpg';
import Image30 from '../../Assets/gallery/30.jpg';
import Image31 from '../../Assets/gallery/31.jpg';
import Image32 from '../../Assets/gallery/32.jpg';
import Image33 from '../../Assets/gallery/33.jpeg';
import Image34 from '../../Assets/gallery/34.jpeg';
import Image35 from '../../Assets/gallery/35.jpeg';
import Image36 from '../../Assets/gallery/36.jpeg';

import vid1 from '../../Assets/gallery/video1.mp4';
// Continue importing other images or dynamically load them

const Gallery = () => {
  const navigate = useNavigate();
  const images = [
    { src: Image1, alt: 'Image 1' },
    { src: Image2, alt: 'Image 2' },
    { src: Image3, alt: 'Image 3' },
    { src: Image4, alt: 'Image 4' },
    { src: Image5, alt: 'Image 5' },
    { src: Image6, alt: 'Image 6' },
    { src: Image7, alt: 'Image 7' },
    { src: Image8, alt: 'Image 8' },
    { src: Image9, alt: 'Image 9' },
    { src: Image10, alt: 'Image 10' },
    { src: Image11, alt: 'Image 11' },
    { src: Image12, alt: 'Image 12' },
    { src: Image13, alt: 'Image 12' },
    { src: Image14, alt: 'Image 12' },
    { src: Image15, alt: 'Image 12' },
    { src: Image16, alt: 'Image 12' },
    { src: Image17, alt: 'Image 12' },
    { src: Image18, alt: 'Image 12' },
    { src: Image19, alt: 'Image 12' },
    { src: Image20, alt: 'Image 12' },
    { src: Image21, alt: 'Image 12' },
    { src: Image22, alt: 'Image 12' },
    { src: Image23, alt: 'Image 12' },
    { src: Image24, alt: 'Image 12' },
    { src: Image25, alt: 'Image 12' },
    { src: Image26, alt: 'Image 12' },
    { src: Image27, alt: 'Image 12' },
    { src: Image28, alt: 'Image 12' },
    { src: Image29, alt: 'Image 12' },
    { src: Image30, alt: 'Image 12' },
    { src: Image31, alt: 'Image 12' },
    { src: Image32, alt: 'Image 12' },
    { src: Image33, alt: 'Image 12' },
    { src: Image34, alt: 'Image 12' },
    { src: Image35, alt: 'Image 12' },
    { src: Image36, alt: 'Image 12' },
    // Add 30+ unique images here
  ];

  const handleBack = () => {
    navigate('/'); // Navigate to the homepage
  };

  return (
    <div>
      <div>
        <h1 className='gallerytext'>Gallery</h1>
        <button className="back-button" onClick={handleBack}>
          Back to Home
        </button>
      </div>
      <div id="gallery" className="gallery-container">
        {images.map((image, index) => (
          <div key={index} className="gallery-item">
            <img src={image.src} alt={image.alt} className="gallery-image animated-image " loading="lazy" />
          </div>
        ))}
      </div>
      <div className="back-button-container">

      </div>
    </div>
  );
};

export default Gallery;
